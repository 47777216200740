exports.defaultItems = [
  {
    title: "Fully agree",
    type: "button",
    weight: 1.0,
  },
  {
    title: "Agree",
    type: "button",
    weight: 0.5,
  },
  {
    title: "Not agree",
    type: "button",
    weight: 0.0,
  },
];

exports.shareText = config => `Dear

{{firstname}} has successfully completed the self-assessment Riemann-Thomann model.

Please find attached the link {{link}} to discuss the results.

Any questions? Feel free to reach out anytime.

Best Regards

${config.title}
${config.email}
`;
