export default function Header({ title, logo }) {
  return (
    <header>
      <div className="container" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
        <a href="/" style={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
          {logo ? <img src={`/${logo}`} alt="logo" style={{ maxHeight: 35, display: "inline-block" }} /> : title}
        </a>
      </div>
    </header>
  );
}
